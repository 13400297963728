<template>
	<div class="hello">
		<div class="container">
			<router-link to="/">Simple Upload</router-link> |
			<router-link to="/form">Google Integration</router-link>
		</div>

		<router-view />
	</div>
</template>

<script>
	export default {
		name: 'ChooseForm',
	};
</script>

<style lang="scss" scoped>
	.container {
		display: flex;
		flex-flow: row nowrap;
		text-align: center;
		align-items: center;
		margin: 20px 0 20px 0;
		padding: 0 0 30px 0;

		* {
			padding: 0 20px;
			width: 200px;
		}

		a {
			color: rgb(27, 27, 27);
			background-color: rgba(243, 149, 215, 0.3);
			border: 1px solid rgba(243, 149, 215, 0.6);
			box-shadow: inset 0px 0px 1px 0px rgba(0, 0, 0, 0.19);
			border-radius: 15px;
			padding: 15px;
			margin: 0 15px;
		}

		.router-link-exact-active {
			color: rgb(27, 27, 27);
			background-color: rgba(243, 149, 215, 0.8);
			box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.39);
			font-weight: 600;
		}
	}
</style>
