<template>
	<div class="container-upload" :class="error ? 'error-upload' : ''">
		<div class="thumb-product">
			<img :src="imageUrl" />
		</div>
		<div class="details">
			<div v-for="(details, key) in product" :key="details.id">
				<p>
					<span v-if="key != 'image'" class="title">{{ key }}:</span>
					{{ details }}
				</p>
			</div>
		</div>
		<div v-if="!error" class="link-product">
			<a :href="url" :class="url ? '' : 'disabled'" target="_blank">
				<span class="btn">Vai al prodotto</span>
			</a>
		</div>
		<div v-else class="link-product">
			{{ error }}
		</div>
	</div>
</template>

<script>
	export default {
		name: 'UploadedProduct',
		props: {
			id: {
				type: String,
			},
			product: {
				type: Object,
				required: true,
			},
			url: {
				type: String,
			},
			imageUrl: {
				type: String,
			},
			error: {
				type: String,
			},
		},
		data() {
			return {};
		},
		methods: {},
	};
</script>

<style scoped lang="scss">
	.container-upload {
		background-color: rgb(118, 255, 137);
		border: 1px solid rgb(59, 255, 85);
		&.error-upload {
			background-color: rgb(255, 98, 98);
			border: 1px solid rgb(255, 70, 70);
			.link-product {
				color: rgb(109, 0, 0);
				font-weight: 700;
			}
		}
		display: flex;
		flex-flow: row wrap;
		padding: 15px;
		align-items: center;

		& * {
			max-height: 100%;
		}

		.thumb-product {
			margin-right: 15px;
		}

		.details {
			display: block;
			font-size: 12px;
			.title {
				font-weight: 700;
			}
			p {
				flex-flow: row;
				margin: 0;
				span {
					margin-right: 5px;
				}
			}

			div {
				display: block;
			}
		}

		img {
			height: 100%;
			width: 100px;
		}

		.link-product {
			margin-left: auto;
		}
	}

	.btn {
		background-color: green;
	}

	.disabled .btn {
		background-color: rgb(138, 138, 138);
	}
</style>
