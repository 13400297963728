var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"form"},[_c('FormulateForm',{staticClass:"login-form",attrs:{"form-errors":_vm.formErrors},on:{"submit":_vm.uploadProducts},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isLoading = ref.isLoading;
return [_c('FormulateInput',{attrs:{"type":"radio","name":"mode","label":"Modalità","options":{
				multi: 'Un articolo per ogni media',
				single: 'Tutti i media in un articolo',
			},"validation":"required","validation-name":"Modalità"}}),_c('FormulateInput',{attrs:{"type":"text","name":"name","label":"Tessuto","help":"IL NOME DELL'ARTICOLO VIENE GENERATO AUTOMATICAMENTE: non inserire info come larghezza o prezzo qui.","validation":"required","validation-name":"Nome tessuto"}}),_c('FormulateInput',{attrs:{"type":"text","name":"brand","label":"Brand (Facoltativo)","validation-name":"Brand"}}),_c('FormulateInput',{attrs:{"type":"number","name":"price","step":"0.01","label":"Prezzo","validation":"bail|required|min:0.01","validation-name":"Prezzo"}}),_c('FormulateInput',{attrs:{"type":"text","name":"supplier","label":"Provenienza","validation":"required","validation-name":"Provenienza"}}),_c('FormulateInput',{attrs:{"type":"number","name":"length","label":"Larghezza (cm)","help":"Inserire Larghezza in cm","validation":"required","validation-name":"Larghezza"}}),_c('FormulateInput',{attrs:{"type":"radio","name":"category","label":"Categoria","options":{
				stock: 'Stock',
				fornitura: 'Fornitura',
				arredo: 'Arredo',
			},"validation":"required","validation-name":"Categoria"}}),_c('FormulateInput',{attrs:{"type":"filepond","name":"mediaSelected","validation":"required","validation-messages":{
				required: 'Devi caricare almeno una foto/video',
			}},on:{"upload":function($event){_vm.isUploading = $event}}}),_c('FormulateInput',{attrs:{"type":"submit","disabled":isLoading || _vm.isUploading,"label":isLoading ? 'Caricamento...' : 'Carica prodotti',"error-validation":"submit"}}),_c('FormulateErrors')]}}]),model:{value:(_vm.formData),callback:function ($$v) {_vm.formData=$$v},expression:"formData"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }