<template>
	<div class="hello">
		<!-- <circle-spin v-if="isLoading" color="#f395d7"></circle-spin> -->
		<CircleSpinner
			:loading="isLoading"
			color="#f395d7"
			height="50"
		></CircleSpinner>
		<div class="error" v-if="errors">
			<p class="message">{{ errors }}</p>
			<router-link to="/"><span class="btn">Torna al form</span></router-link>
		</div>
		<div class="container-scrollable" v-if="!errors">
			<div v-for="product in uploadedProducts" :key="product.id">
				<UploadedProduct
					:id="product.id"
					:product="{
						nome: product.name,
						data: product.timestamp.toLocaleString('it-IT'),
						larghezza: product.length,
					}"
					:url="product.url"
					:imageUrl="product.imageUrl"
					:error="product.error"
				/>
			</div>
			<CircleSpinner
				:loading="hasNext"
				margin="40px auto"
				height="30"
			></CircleSpinner>
		</div>
		<div v-if="complete" class="complete">
			<div v-if="!almostComplete && formData.channel == 'google_drive'">
				<CircleSpinner color="#f395d7" height="20"></CircleSpinner>
			</div>
			<div v-else class="almostComplete">
				<p>Caricamento completato!</p>
				<router-link to="/">
					<span class="btn">Carica altri prodotti</span>
				</router-link>
			</div>
		</div>
	</div>
</template>

<script>
	import { mapState } from 'vuex';
	import UploadedProduct from '@/components/UploadedProduct.vue';
	import CircleSpinner from '@/components/CircleSpinner.vue';

	export default {
		name: 'Upload',
		components: {
			UploadedProduct,
			CircleSpinner,
		},
		data() {
			return {
				errors: '',
				uploadedProducts: [],
				hasNext: false,
				complete: false,
				almostComplete: false,
			};
		},
		computed: {
			isLoading: function() {
				return (
					Array.isArray(this.uploadedProducts) &&
					this.uploadedProducts.length == 0 &&
					this.errors == ''
				);
			},
			...mapState(['formData']),
		},

		async mounted() {
			switch (this.formData.channel) {
				case 'google_drive':
					this._gdriveUpload(this.formData);
					break;
				case 'simple':
					this._simpleUpload(this.formData);
					break;
				default:
					this.errors = 'Devi prima compilare il form';
					break;
			}
		},

		methods: {
			_simpleUpload: async function(upload) {
				try {
					// Creating files array
					let media = [];
					let files = upload.mediaSelected.map(file => {
						return {
							name: file.name,
							mimeType: file.type,
							size: file.size,
						};
					});

					//console.log('FILES: ', files);

					// Create presigned URL
					let urls = await this.$axios.post('api/upload/create-urls', {
						files: files,
					});

					urls = urls.data.products;
					//console.log('URLS: ', urls);

					// Create form-data multi upload POST data
					for (let [index, product] of urls.entries()) {
						let form = new FormData();
						let linkUpload = product.params;
						for (let param of linkUpload) {
							form.append(param.name, param.value);
						}
						form.append('file', upload.mediaSelected[index].file);

						// Upload file to that URL

						// Using fetch instead of Axios for having access to no-cors option
						await fetch(product.url, {
							method: 'POST',
							mode: 'no-cors', // remove all not simple-headers, preventing preflighting the request ( https://evertpot.com/no-cors/ )
							body: form,
						});

						this.uploadedProducts.push({
							name: upload.name,
							length: upload.length,
							timestamp: new Date(),
						});

						media.push({
							originalSource: product.resourceUrl,
							alt: upload.name + ' ' + upload.length,
							mediaContentType: product.mediaType,
						});

						this.hasNext = true;
					}

					this.hasNext = false;
					// Create products in Shopify with that media
					this.almostComplete = true;
					if (upload.mode == 'multi') {
						for (let [index, singleMedia] of media.entries()) {
							let productCreated = await this.$axios.post(
								'api/upload/create-product',
								{
									info: {
										name: upload.name,
										length: upload.length,
										brand: upload.brand,
										price: upload.price,
										supplier: upload.supplier,
										category: upload.category,
									},
									media: [singleMedia],
								}
							);

							productCreated = productCreated.data;

							//console.log('PRODUCT CREATED: ', productCreated);

							this.uploadedProducts[index] = {
								...this.uploadedProducts[index],
								...productCreated,
							};
						}
					} else if (upload.mode == 'single') {
						let productCreated = await this.$axios.post(
							'api/upload/create-product',
							{
								info: {
									name: upload.name,
									length: upload.length,
									brand: upload.brand,
									price: upload.price,
									supplier: upload.supplier,
									category: upload.category,
								},
								media: media,
							}
						);

						productCreated = productCreated.data;

						//console.log('PRODUCT CREATED: ', productCreated);

						this.uploadedProducts = this.uploadedProducts.map((el, index) => {
							return {
								...el,
								...productCreated,
								id: index.toString(),
							};
						});
					}
					this.almostComplete = true;
					this.complete = true;
				} catch (e) {
					this.errors = e;
				}
			},
			_gdriveUpload: async function(upload) {
				let res;
				try {
					if (this.formData.mode == 'multi') {
						for (let product of this.formData.mediaSelected) {
							upload.mediaSelected = [product]; // Assign the product as the first element of the array cause /api/upload take an array as req.body.mediaSelected (to make possible SINGLE mode)
							res = await this.$axios.post('/api/upload/google-drive', upload);

							this.uploadedProducts.push({
								name: this.formData.name,
								length: this.formData.length,
								...res.data,
							});

							// For loading spinner inside the loop
							this.hasNext = true;
						}

						// For loading spinner inside the loop
						this.hasNext = false;
					} else if (this.formData.mode == 'single') {
						res = await this.$axios.post('/api/upload/google-drive', upload);

						this.uploadedProducts.push({
							name: this.formData.name,
							length: this.formData.length,
							...res.data,
						});
					}
				} catch (e) {
					//console.log(e);
					//this.errors = e.response.data.error.message;
				}

				let ids = this.uploadedProducts.map(el => {
					return el.id;
				});

				this.complete = true;
				await this.$axios.post('/api/privateFiles', {
					ids: ids,
				});
				this.almostComplete = true;
			},
		},
	};
</script>

<style scoped lang="scss">
	.container-scrollable {
		height: 500px;
		padding: 0 16px;
		overflow-y: auto;
		width: 100%;

		&::-webkit-scrollbar {
			width: 7px;
			height: 7px;
		}
		&::-webkit-scrollbar-button {
			width: 0px;
			height: 0px;
		}
		&::-webkit-scrollbar-thumb {
			background: #f995d7;
			border: 0px none #ffffff;
			border-radius: 50px;
		}
		&::-webkit-scrollbar-thumb:hover {
			background: #edb6db;
		}
		&::-webkit-scrollbar-thumb:active {
			background: #ec55bc;
		}
		&::-webkit-scrollbar-track {
			background: #dbdbdb;
			border: 0px none #ffffff;
			border-radius: 16px;
		}
		&::-webkit-scrollbar-track:hover {
			background: #dbdbdb;
		}
		&::-webkit-scrollbar-track:active {
			background: #bebebe;
		}
		&::-webkit-scrollbar-corner {
			background: transparent;
		}
	}

	.error {
		font-size: 20px;
		color: red;
	}

	.complete {
		font-size: 20px;
		color: rgb(9, 104, 0);
		text-align: center;
	}
</style>
