<template>
	<div class="form">
		<FormulateForm
			@submit="uploadProducts"
			class="login-form"
			v-model="formData"
			#default="{ isLoading }"
			:form-errors="formErrors"
		>
			<FormulateInput
				type="radio"
				name="mode"
				label="Modalità"
				:options="{
					multi: 'Un articolo per ogni media',
					single: 'Tutti i media in un articolo',
				}"
				validation="required"
				validation-name="Modalità"
			/>
			<FormulateInput
				type="text"
				name="name"
				label="Tessuto"
				help="IL NOME DELL'ARTICOLO VIENE GENERATO AUTOMATICAMENTE: non inserire info come larghezza o prezzo qui."
				validation="required"
				validation-name="Nome tessuto"
			/>
			<FormulateInput
				type="text"
				name="brand"
				label="Brand (Facoltativo)"
				validation-name="Brand"
			/>
			<FormulateInput
				type="number"
				name="price"
				step="0.01"
				label="Prezzo"
				validation="bail|required|min:0.01"
				validation-name="Prezzo"
			/>
			<FormulateInput
				type="text"
				name="supplier"
				label="Provenienza"
				validation="required"
				validation-name="Provenienza"
			/>
			<FormulateInput
				type="number"
				name="length"
				label="Larghezza (cm)"
				help="Inserire Larghezza in cm"
				validation="required"
				validation-name="Larghezza"
			/>
			<FormulateInput
				type="radio"
				name="category"
				label="Categoria"
				:options="{
					stock: 'Stock',
					fornitura: 'Fornitura',
					arredo: 'Arredo',
				}"
				validation="required"
				validation-name="Categoria"
			/>
			<FormulateInput
				type="drive"
				name="mediaSelected"
				validation="required"
				:validation-messages="{
					required: 'Devi caricare almeno una foto/video',
				}"
			/>
			<FormulateInput
				type="submit"
				:disabled="isLoading"
				:label="isLoading ? 'Caricamento...' : 'Carica prodotti'"
				error-validation="submit"
			/>
			<FormulateErrors />
		</FormulateForm>
	</div>
</template>

<script>
	export default {
		name: 'Form',
		data() {
			return {
				formData: {
					name: '',
					mode: 'multi',
					price: null,
					brand: '',
					supplier: '',
					length: null,
					channel: 'google_drive',
					mediaSelected: [],
					category: 'stock',
				},
				formErrors: [],
			};
		},

		methods: {
			uploadProducts: async function(data) {
				this.$store.commit('setFormData', data);
				let next = true;

				let ids = data.mediaSelected.map(el => {
					return el.id;
				});
				try {
					await this.$axios.post('/api/publicFiles', { ids: ids });
				} catch (e) {
					this.formErrors = [e.response.data['error_description']];
					next = false;
				}
				if (next) this.$router.push('/upload');

				return;
			},
		},
	};
</script>

<style lang="scss">
	.formulate-input[data-classification='group'] {
		& .formulate-input-group {
			flex-flow: row;
			width: 100%;
			margin: 15px 0;
		}
	}

	.formulate-input[data-classification='box'] {
		label {
			font-weight: 400;
		}
	}
</style>
