<template>
	<div class="form">
		<FormulateForm
			@submit="uploadProducts"
			class="login-form"
			v-model="formData"
			#default="{ isLoading }"
			:form-errors="formErrors"
		>
			<FormulateInput
				type="radio"
				name="mode"
				label="Modalità"
				:options="{
					multi: 'Un articolo per ogni media',
					single: 'Tutti i media in un articolo',
				}"
				validation="required"
				validation-name="Modalità"
			/>
			<FormulateInput
				type="text"
				name="name"
				label="Tessuto"
				help="IL NOME DELL'ARTICOLO VIENE GENERATO AUTOMATICAMENTE: non inserire info come larghezza o prezzo qui."
				validation="required"
				validation-name="Nome tessuto"
			/>
			<FormulateInput
				type="text"
				name="brand"
				label="Brand (Facoltativo)"
				validation-name="Brand"
			/>
			<FormulateInput
				type="number"
				name="price"
				step="0.01"
				label="Prezzo"
				validation="bail|required|min:0.01"
				validation-name="Prezzo"
			/>
			<FormulateInput
				type="text"
				name="supplier"
				label="Provenienza"
				validation="required"
				validation-name="Provenienza"
			/>
			<FormulateInput
				type="number"
				name="length"
				label="Larghezza (cm)"
				help="Inserire Larghezza in cm"
				validation="required"
				validation-name="Larghezza"
			/>
			<FormulateInput
				type="radio"
				name="category"
				label="Categoria"
				:options="{
					stock: 'Stock',
					fornitura: 'Fornitura',
					arredo: 'Arredo',
				}"
				validation="required"
				validation-name="Categoria"
			/>
			<FormulateInput
				type="filepond"
				name="mediaSelected"
				validation="required"
				:validation-messages="{
					required: 'Devi caricare almeno una foto/video',
				}"
				@upload="isUploading = $event"
			/>
			<FormulateInput
				type="submit"
				:disabled="isLoading || isUploading"
				:label="isLoading ? 'Caricamento...' : 'Carica prodotti'"
				error-validation="submit"
			/>
			<FormulateErrors />
		</FormulateForm>
	</div>
</template>

<script>
	export default {
		name: 'SimpleForm',
		data() {
			return {
				formData: {
					name: '',
					mode: 'multi',
					price: null,
					brand: '',
					supplier: '',
					length: null,
					mediaSelected: [],
					category: 'stock',
					channel: 'simple',
				},
				formErrors: [],
				isUploading: false,
			};
		},

		methods: {
			uploadProducts: function() {
				this.$store.commit('setFormData', this.formData);
				this.$router.push('/upload');

				return;
			},
			log: function(e) {
				// eslint-disable-next-line no-console
				console.log(e);
			},
		},
	};
</script>

<style lang="scss">
	.formulate-input[data-classification='group'] {
		& .formulate-input-group {
			flex-flow: row !important;
			width: 100%;
			margin: 15px 0;
		}
	}

	.formulate-input[data-classification='box'] {
		label {
			font-weight: 400;
		}
	}

	.form {
		width: 100%;

		div :not([data-type='filepond'].formulate-input *) {
			display: flex;
			align-items: center;
			flex-flow: column;
			justify-content: center;

			.formulate-input-wrapper {
				margin: 0 25px;
			}
		}

		.formulate-input-help {
			margin: auto;
		}

		.filepond--root {
			margin-bottom: 0px;
		}

		.filepond--wrapper {
			margin: 30px 70px 0 70px;
		}

		.filepond--credits {
			display: none !important;
		}

		.formulate-input-errors {
			text-align: center;
		}
	}
</style>
